<template>
  <div>
    <!-- TODO: add success info and error message -->
    <template v-if="success">
      <CTypo tstyle="title1" class="mb-6">
        {{ $t('accountWasVerified') }}
      </CTypo>
      <div class="mb-6">
        {{ $t('successMessages.accountWasVerified') }}
      </div>
      <CButton variant="primary" @click="toLogin">
        Zum Login
      </CButton>
    </template>
    <CMessage v-else-if="success === false && !emailWasResent" variant="danger">
      {{ $t('errorMessages.verificationFailed') }}
      <template v-slot:copy>
        <template v-if="!email">
          {{ $t('errorMessages.verificationUrlInvalid') }}
        </template>
        <template v-else>
          {{ $t('errorMessages.verificationUrlExpired') }}
        </template>
      </template>
      <template v-if="email" v-slot:action>
        <button class="uppercase font-medium underline" @click="resendVerification">
          {{ $t('resend') }}
        </button>
      </template>
    </CMessage>
    <CMessage v-else-if="success === false && emailWasResent" variant="success">
      {{ $t('successMessages.resendVerificationMail') }}
    </CMessage>
  </div>
</template>

<script>
import { RESEND_VERIFICATION, VERIFY_EMAIL } from '@/store/actionTypes';

export default {
  data: () => ({
    success: null,
    emailWasResent: false,
  }),

  computed: {
    email() {
      return this.$route.params.param;
    },
    signature() {
      return this.$route.query.signature;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.verifyEmail();
    });
  },

  methods: {
    verifyEmail() {
      if (this.signature && this.email) {
        this.$store
          .dispatch(VERIFY_EMAIL, {
            email: this.email,
            signature: this.signature,
          })
          .then(() => {
            this.success = true;
          })
          .catch(() => {
            this.success = false;
          });
      } else {
        this.success = false;
      }
    },
    async resendVerification() {
      await this.$store.dispatch(RESEND_VERIFICATION, this.email);
      this.emailWasResent = true;
    },
    toLogin() {
      this.$store.state.auth.savedLoginEmail = this.email;
      this.$router.push('/login');
    },
  },
};
</script>
