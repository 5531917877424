<template>
  <FormSplitLayout v-if="!hideSignupPage">
    <template v-slot:left>
      <div class="flex items-center justify-between">
        <CTypo tstyle="title2">contimo</CTypo>
        <CLink
          href="https://contimo.de"
          class="font-medium text-gray-700"
        >
          {{ $t('backToWebsite') }}
        </CLink>
      </div>
      <div class="mt-6 md:mt-16 lg:mt-20 xl:mt-32 lg:flex-grow">
        <div>
          <CTypo
            tstyle="caption1"
            class="text-gray-700 mb-1"
          >
            {{ $t('signup') }}
          </CTypo>
          <CTypo tstyle="title1" class="max-w-xs">
            {{ $t('creatingContainerShopIn4Steps') }}
          </CTypo>
          <div class="hidden md:block mt-4 lg:mt-6">
            <CTypo
              v-for="(step, index) in signupSteps"
              :key="`step_${index}`"
              tstyle="body"
              class="mb-3 transition-colors duration-300"
              :class="{
                'text-primary':
                  [0, 1].includes(index) && ['password', 'verify'].includes(currentStep),
                'text-gray-700': !stepActive(index),
                'text-primary font-bold': stepActive(index),
              }"
              page
            >
              {{ index + 1 }}. {{ step }}
            </CTypo>
          </div>
          <template v-if="currentStep === 'data'">
            <CDivider class="mt-4 md:mt-8" />
            <CTypo el="span" class="text-gray-600 mr-2">{{ $t('haveAnAccount') }}</CTypo>
            <CLink
              to="/login"
              class="text-primary font-medium"
            >
              {{ $t('loginNow') }}
            </CLink>
          </template>
        </div>
      </div>
    </template>
    <SignupFormData v-if="currentStep === 'data'" />
    <SignupFormPassword v-else-if="currentStep === 'password'" />
    <SignupFormVerify v-else-if="currentStep === 'verify'" />
    <template v-slot:footer>
      <div class="mb-3">
        <CLink
          v-for="link in footerLinks"
          :key="link.href"
          :href="link.href"
          class="text-gray-600 inline-flex mr-2"
          target="_blank"
          small
        >
          {{ link.label }}
        </CLink>
      </div>
      <CTypo tstyle="footnote2" class="text-gray-600 text-xs">
        © 2021 contimo GmbH
      </CTypo>
    </template>
  </FormSplitLayout>
</template>

<script>
import FormSplitLayout from '@/ui/layouts/FormSplit.vue';
import SignupFormData from '@/ui/components/SignupForm/Data/index.vue';
import SignupFormPassword from '@/ui/components/SignupForm/Password/index.vue';
import SignupFormVerify from '@/ui/components/SignupForm/Verify/index.vue';
import { CONTACT, IMPRINT, PRIVACY, TERMS } from '@/constants/links';

export default {
  components: {
    FormSplitLayout,
    SignupFormData,
    SignupFormPassword,
    SignupFormVerify,
  },

  computed: {
    currentStep() {
      return this.$route.params.step;
    },
    footerLinks() {
      return [
        { href: CONTACT, label: this.$t('contact') },
        { href: TERMS, label: this.$t('terms') },
        { href: PRIVACY, label: this.$t('privacy') },
        { href: IMPRINT, label: this.$t('imprint') },
      ];
    },
    signupSteps() {
      return [
        this.$t('signupSteps.fillData'),
        this.$t('signupSteps.setPassword'),
        this.$t('signupSteps.legalInformation'),
        this.$t('signupSteps.publishContainerShop'),
      ];
    },
    hideSignupPage() {
      return process.env.VUE_APP_SIGNUP_NOT_ALLOWED === 'true';
    },
  },

  methods: {
    stepActive(index) {
      if (index === 0 && this.currentStep === 'data') {
        return true;
      }
      if (index === 1 && this.currentStep === 'password') {
        return true;
      }
      return false;
    },
  },
};
</script>
