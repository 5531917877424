<template>
  <form @submit.prevent="nextStep">
    <div class="mb-3">
      <CTypo tstyle="caption1" class="text-gray-500">
        {{ $t('personalData') }}
      </CTypo>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-4">
      <div>
        <CTextField
          v-model="$store.state.signup.signupData.firstName"
          :label="$t('firstName')"
          autocomplete="given-name"
          v-autofocus
          required
        />
      </div>
      <div>
        <CTextField
          v-model="$store.state.signup.signupData.lastName"
          :label="$t('lastName')"
          autocomplete="family-name"
          required
        />
      </div>
    </div>
    <div>
      <CTextField
        v-model="$store.state.signup.signupData.email"
        :label="$t('email')"
        type="email"
        autocomplete="email"
        required
      />
    </div>
    <div>
      <CTextField
        v-model="$store.state.signup.signupData.phone"
        :label="$t('phone')"
        type="tel"
        autocomplete="tel"
        required
      />
    </div>
    <div class="mt-8 mb-3">
      <CTypo tstyle="caption1" class="text-gray-500">
        {{ $t('companyData') }}
      </CTypo>
    </div>
    <div>
      <CTextField
        v-model="$store.state.signup.merchantSignupData.merchantName"
        :label="$t('companyNameIncludingLegalForm')"
        :placeholder="$t('placeholder.merchantName')"
        autocomplete="organization"
        required
      />
    </div>
    <div>
      <CSelect
        :value="$store.state.signup.merchantSignupData.address.countryShort"
        :label="$t('address.country')"
        disabled
        required
      >
        <option
          v-for="country in countries"
          :key="country.short"
          :value="country.short"
        >
          {{ $t(country.translationSlug) }}
        </option>
        <template v-slot:message>
          {{ $t('currentlyWeOfferServicesOnlyInGermany') }}
        </template>
      </CSelect>
    </div>
    <div class="grid grid-cols-12 gap-x-4">
      <div class="col-span-8 md:col-span-9">
        <CTextField
          v-model="$store.state.signup.merchantSignupData.address.street"
          :label="$t('address.street')"
          required
        />
      </div>
      <div class="col-span-4 md:col-span-3">
        <CTextField
          v-model="$store.state.signup.merchantSignupData.address.number"
          :label="$t('address.number')"
          required
        />
      </div>
      <div class="col-span-12">
        <CTextField
          :label="$t('address.postalCode')"
          :data="currentPostalCodeResults"
          :debounce-typing="150"
          :clear-on-select="false"
          field="postalCode"
          required
          autocomplete-component
          @typing="searchPostalCode"
          @select="postalCodeSelect"
        >
          <template v-slot="props">
            {{ props.option.postalCode }} {{ props.option.city }}
          </template>
        </CTextField>
      </div>
      <div class="col-span-12">
        <CTextField
          v-if="!helper.customCity"
          :label="$t('address.city')"
          :value="currentCity"
          :disabled="!helper.customCity"
        />
      </div>
    </div>
    <div class="flex justify-end">
      <CButton native-type="submit" variant="primary">{{ $t('continue') }}</CButton>
    </div>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SEARCH_POSTAL_CODES } from '@/store/actionTypes';
import { CURRENT_POSTAL_CODE_RESULTS } from '@/store/gettersTypes';
import { RESET_POSTAL_CODE_SEARCH } from '@/store/mutationsTypes';

export default {
  data: () => ({
    selectedPostalCode: {},
  }),

  computed: {
    ...mapGetters([CURRENT_POSTAL_CODE_RESULTS]),
    countries() {
      return this.$store.state.ui.countries;
    },
    helper() {
      return this.$store.state.signup.merchantHelper;
    },
    currentCity() {
      if (this.customCity) return this.$store.state.signup.merchantSignupData.address.city;
      return this.helper.selectedPostalCode?.city || null;
    },
  },

  methods: {
    ...mapActions([SEARCH_POSTAL_CODES]),
    ...mapMutations([RESET_POSTAL_CODE_SEARCH]),
    searchPostalCode(value) {
      const currentCountry = this.$store.state.signup.merchantSignupData.address.countryShort;
      if (value.length >= 3) {
        this.searchPostalCodes(`${currentCountry}-${value}`).then(() => {
          if (value.length === 5 && this.currentPostalCodeResults.length > 0) {
            this.postalCodeSelect(this.currentPostalCodeResults[0]);
          } else if (value.length === 5) {
            this.postalCodeSelect(null);
          }
        });
      } else {
        this.resetPostalCodeSearch();
      }
    },
    nextStep() {
      this.$router.push('/signup/password');
    },
    postalCodeSelect(data) {
      this.helper.selectedPostalCode = data;
      this.$store.state.signup.merchantSignupData.address.postalCodeId = data?.id || '';
    },
  },
};
</script>
