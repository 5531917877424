<template>
  <form v-if="!signupSuccess" @submit.prevent="submitSignupData">
    <CTextField
      v-model="$store.state.signup.signupData.password"
      :label="$t('password')"
      :minlength="minLength"
      type="password"
      required
      v-autofocus
      :disabled="signupSuccess"
      :custom-error="strengthError"
      @input="checkPasswordStrength"
      @blur="showStrengthError"
    />
    <CTextField
      v-model="passwordRepeat"
      :label="$t('repeatPassword')"
      :minlength="minLength"
      type="password"
      required
      :disabled="signupSuccess"
      :custom-error="passwordRepeatError"
      @change="checkPasswordRepeat"
      @blur="checkPasswordRepeat"
    />
    <CCheckbox v-model="terms" required>
      <span v-html="$t('acceptTerms', { href: 'https://contimo.de/agb' })" />
    </CCheckbox>
    <CCheckbox v-model="privacy" required>
      <span v-html="$t('acceptPrivacy', { href: 'https://contimo.de/datenschutz' })" />
    </CCheckbox>
    <!-- TODO: add terms checkbox -->
    <CMessage v-if="signupSuccess === false" variant="danger" class="mb-4">
      {{ $t('errorMessages.signupFailed') }}
      <template v-if="signupFieldErrors.length > 0" v-slot:copy>
        {{ $t('errorMessages.signupFailedPleaseControlFollowingFields') }}
        <ul class="list-disc list-inside">
          <CTypo
            v-for="field in signupFieldErrors"
            :key="field"
            el="li"
          >
            {{ $t(field) }}
          </CTypo>
        </ul>
      </template>
      <template v-else-if="emailAlreadyUsedError">
        {{ $t('errorMessages.emailAlreadyUsed') }}
      </template>
    </CMessage>
    <div class="flex justify-between items-center">
      <CButton to="/signup/data">
        {{ $t('back') }}
      </CButton>
      <CButton native-type="submit" variant="primary" :loading="loading">
        {{ $t('signupNow') }}
      </CButton>
    </div>
  </form>
  <div v-else>
    <CTypo tstyle="title1" class="mb-6">
      {{ $t('welcome') }}
    </CTypo>
    <div class="mb-3">
      {{ $t('successMessages.signupWasSuccessful', {
        email: $store.state.signup.signupData.email,
      }) }}
    </div>
    <template v-if="!emailWasResent">
      <div class="mb-6">{{ $t('messages.ifYouCantFindTheVerificationMail') }}</div>
      <CButton variant="tertiary" @click="resendVerification">
        {{ $t('resend') }}
      </CButton>
    </template>
    <template v-else>
      <CMessage variant="success">
        {{ $t('successMessages.resendVerificationMail') }}
        <template v-slot:copy>
          {{ $t('successMessages.noVerificationMailContact') }}
        </template>
        <template v-slot:action>
          <a href="/contact">{{ $t('contact') }}</a>
        </template>
      </CMessage>
    </template>
    <CDivider />
    <router-link to="/login">
      <CButton variant="primary">{{ $t('loginNow') }}</CButton>
    </router-link>
  </div>
</template>

<script>
import { RESEND_VERIFICATION, SIGNUP_MERCHANT } from '@/store/actionTypes';
import { MIN_PASSWORD_STRENGTH, MIN_PASSWORD_LENGTH } from '@/constants/ui';

export default {
  data: () => ({
    zxcvbn: null,
    strength: -1,
    strengthError: null,
    showStrength: false,
    signupSuccess: null,
    signupFieldErrors: [],
    passwordRepeatError: null,
    passwordRepeat: null,
    emailWasResent: false,
    terms: false,
    privacy: false,
    loading: false,
    emailAlreadyUsedError: false,
  }),

  computed: {
    minLength() {
      return MIN_PASSWORD_LENGTH;
    },
  },

  created() {
    import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn').then((zxcvbn) => {
      this.zxcvbn = zxcvbn.default;
      this.showStrength = true;
    });
  },

  methods: {
    checkPasswordStrength(value) {
      if (value && value.length >= 8) {
        this.strength = this.zxcvbn(value).score || 0;
      }
      if (this.strengthError) {
        this.showStrengthError();
      }
    },
    showStrengthError() {
      if (this.strength > -1 && this.strength <= MIN_PASSWORD_STRENGTH) {
        this.strengthError = this.$t('fieldErrors.passwordWeak');
      } else {
        this.strengthError = false;
      }
    },
    submitSignupData() {
      this.checkPasswordStrength();
      this.checkPasswordRepeat();
      if (
        !this.passwordRepeatError &&
        (process.env.NODE_ENV === 'development' || this.strength >= MIN_PASSWORD_STRENGTH)
      ) {
        this.loading = true;
        this.emailAlreadyUsedError = false;

        this.$store
          .dispatch(SIGNUP_MERCHANT)
          .then(() => {
            this.signupSuccess = true;
            this.loading = false;
          })
          .catch((e) => {
            this.signupSuccess = false;
            this.signupFieldErrors = [];
            if (
              e.response.data.code === 'E_VALIDATION_FAILURE' &&
              e.response.data.details &&
              e.response.data.details.errors
            ) {
              e.response.data.details.errors.forEach((error) => {
                this.signupFieldErrors.push(error.field);
              });
            }

            if (e.response.data.code === 'E_EMAIL_ALREADY_USED') {
              this.emailAlreadyUsedError = true;
            }

            this.loading = false;
          });
      }
    },
    checkPasswordRepeat() {
      if (this.passwordRepeat !== this.$store.state.signup.signupData.password) {
        this.passwordRepeatError = this.$t('fieldErrors.passwordsAreNotTheSame');
      } else {
        this.passwordRepeatError = null;
      }
    },
    async resendVerification() {
      await this.$store.dispatch(RESEND_VERIFICATION);
      this.emailWasResent = true;
    },
  },
};
</script>
